<!--
 * @Author: jjskay 404784102@qq.com
 * @Date: 2020-10-14 10:24:09
 * @LastEditors: jjskay 404784102@qq.com
 * @LastEditTime: 2022-07-05 10:11:56
 * @FilePath: /iot-product-tool/src/components/ryProbe/checkListItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div :class="{'component-check-list-item': true, 'pass': passed}" @click="clickItem">
        <div class="item-title">{{probeId}}</div>
        <div class="item-time">{{time}}</div>
        <Icon name="arrow" />
    </div>
</template>

<script>
import { Icon } from "vant";
export default {
    name: "CheckListItem",
    components: {
        Icon
    },
    props: {
        item: Object,
        default() {
            return {}
        }
    },
    data() {
        const { probeId, updateAt, passed } = this.item
        let _probeId = '';
        let time = ''
        if (probeId) {
            _probeId += probeId.slice(0, 4);
            _probeId += '  ';
            _probeId += probeId.slice(4, 8);
            _probeId += '  ';
            _probeId += probeId.slice(8, 12);
            _probeId += '  ';
            _probeId += probeId.slice(12, 16);
        }

        if (updateAt) {
            const t = updateAt.split('T').length > 1 ? new Date(updateAt) : new Date(updateAt * 1000);
            time = t.getFullYear();
            time += `/${t.getUTCMonth() + 1 > 9 ? t.getUTCMonth() + 1 : '0' + (t.getUTCMonth() + 1)}`
            time += `/${t.getDate() > 9 ? t.getDate() : '0' + t.getDate()}`
            time += `   ${t.getHours() > 9 ? t.getHours() : '0' + t.getHours()}`
            time += `:${t.getMinutes() > 9 ? t.getMinutes() : '0' + t.getMinutes()}`
            time += `:${t.getSeconds() > 9 ? t.getSeconds() : '0' + t.getSeconds()}`
        }
        return {
            probeId: _probeId,
            time,
            passed
        }
    },
    methods: {
        clickItem() {
            const vm = this
            vm.$router.push({
                path: "/ryProbe/info",
                query: { uuid: vm.item.probeId }
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.component-check-list-item {
    position: relative;
    padding: 0.1rem 0.2rem;
    border-bottom: 1px solid #f6f9fa;
    color: #313131;
    font-size: 0.16rem;
    &:before {
        content: " ";
        display: block;
        width: 0.1rem;
        height: 0.1rem;
        position: absolute;
        left: 0.05rem;
        top: 0.17rem;
        background: #fb3d66;
        border-radius: 0.05rem;
    }
    &.pass:before {
        background: #256fd9;
    }
    .item-title {
        font-weight: 600;
        padding-bottom: 0.15rem;
    }
    .item-time {
        font-size: 0.12rem;
    }
    .van-icon-arrow {
        position: absolute;
        right: 0.2rem;
        top: 50%;
        margin-top: -8px;
    }
}
</style>
